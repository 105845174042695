@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Rubik&display=swap');

/* ========================== font guide
* font-family: 'Barlow', sans-serif; // base font
* font-family: 'Chango', cursive; // effect font
* font-family: 'Source Sans Pro', sans-serif;  // clean font
*/

* {
  font-family: 'sora-regular';
}

::-webkit-scrollbar {
  display: none;
}
html {
  scroll-snap-type: y mandatory;
}

nav {
  scroll-snap-align: start;
}
body {
  /* base font */
  font-family: 'sora-regular';
  font-size: 14px;
  color: #333;
  background-color: #000;
}

/* @font-face {
  font-family: 'sora-bold';
  src: url('assets/fonts/Sora/static/Sora-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'sora-thin';
  src: url('assets/fonts/Sora/static/Sora-Thin.ttf') format('truetype');
} */

@font-face {
  font-family: 'sora-regular';
  src: url('assets/font/sora/static/Sora-Regular.ttf') format('openType');
}

.general,
.platform {
  color: #fff;
}

.active {
  color: #ffc529;
}

button {
  padding: 0;
  margin: 0;
}

.Mui-selected {
  color: #ffffff !important;
  height: 60px;
}

.MuiButton-endIcon {
  margin-top: -1px;
  margin-left: 4px !important;
}

strong {
  font-weight: 700;
  font-size: 18px;
}

@media (max-width: 767.95px) {
  html {
    scroll-snap-type: none;
  }

  nav {
    scroll-snap-align: inherit;
  }
}
